<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{$route.query.type == 1?'添加素材':$route.query.type == 2?"查看素材":"编辑素材" }}</span>
    </div>
    <div
      class="content"
      id="content"
    >
      <!-- 标题 -->
      <div class="titile pdl-35">
        <h2 class="twoh2 mgb-20">
          <span class="tpointer"></span>
          <span class="ttxt">标题/正文</span>
        </h2>
        <a-row class="flex mgt-15 mgb-20 align-item-center">
          <a-input
            class="noborder_input mgr-12"
            placeholder="请在这里输入标题"
            v-model="query.title"
            :max-length="30"
          ></a-input><span class="pdt-12">{{ query.title?.length||0 }}<span class="fontC4">/30</span> </span>
        </a-row>
        <!-- 富文本 -->
        <Editor
          ref="edit"
          v-model="html"
        ></Editor>
        <h2 class="twoh2 mgt-52 mgb-24">
          <span class="tpointer"></span>
          <span class="ttxt">封面</span>
        </h2>
        <!-- 上传图片 拖拽 -->
        <a-row class="flex nomal_checkbox   mgt-24">
          <span class="leftLable"></span>
          <div class="flex-1">
            <div class="upBox mgt-24">
              <a-upload-dragger
                class="upCom"
                name="file"
                :multiple="true"
                :customRequest="uploadFiles"
                :before-upload="beforeUpload"
                @change="handleChange"
              >
                <div
                  class="upcontent"
                  v-if="!query.cover"
                >
                  <p class="ant-upload-drag-icon">
                    <a-dropdown>
                      <a
                        class="ant-dropdown-link"
                        @click="e => e.preventDefault()"
                      >
                        <i class="meiye-icon  meiye-shangchuan"></i>
                      </a>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <a
                            href="javascript:;"
                            @click="chooseFromEdit"
                          >从正文选择</a>
                        </a-menu-item>
                        <a-menu-item>
                          <a-upload
                            name="file"
                            :multiple="true"
                            :customRequest="uploadFiles"
                            :before-upload="beforeUpload"
                            @change="handleChange"
                          >
                            <span> 本地上传</span>
                          </a-upload>
                          <!-- <a href="javascript:;"> 本地上传</a> -->
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>

                  </p>
                  <p class="ant-upload-text mgt--12">
                    点击上传 或直接将图片文件拖入此区域
                  </p>
                  <p class="ant-upload-hint">
                    只可上传1张图片，JPG/png格式，10M以内
                  </p>
                </div>
                <img
                  class="upcenter"
                  v-else
                  :src="query.cover"
                  alt=""
                >
              </a-upload-dragger>

            </div>
          </div>
        </a-row>

        <h2
          class="twoh2 mgt-52 mgb-24"
          v-if="false"
        >
          <span class="tpointer"></span>
          <span class="ttxt">上传文件</span>
        </h2>
        <!-- 上传文件 拖拽 -->
        <div
          class="upBox mgt-24"
          v-if="false"
        >
          <a-upload-dragger
            class="upCom"
            name="file"
            :multiple="true"
            :customRequest="filesuploadFiles"
            @change="handleChange"
          >
            <div
              class="upcontent"
              v-if="!query.fileUrl"
            >
              <p class="ant-upload-text mgt--12">
                点击 或者将文件拖入此区域
              </p>
            </div>
            <span v-else>{{query.fileUrl  }}</span>
          </a-upload-dragger>
        </div>
      </div>
      <h2 class="twoh2 mgt-52 mgb-24 pdl-35">
        <span class="tpointer"></span>
        <span class="ttxt">文章设置</span>
      </h2>
      <a-row class="flex nomal_checkbox  pdl-35 mgt-24">
        <span class="leftLable">标签</span>
        <div class="rightLable">
          <a-checkbox-group v-model="query.tag">
            <p class="rlp1">
              <a-checkbox :value="1">
                置顶 <span class="font5">（设置之后，携带该标签的图文在标题前展示“【置顶】”）</span>
              </a-checkbox>
            </p>
            <p class="rlp1">
              <a-checkbox :value="2">
                推荐 <span class="font5">（设置之后，携带该标签的图文在标题后展示“【推荐】”）</span>
              </a-checkbox>
            </p>
            <p class="rlp1">
              <a-checkbox :value="3">
                热门 <span class="font5">
                  （设置之后，携带该标签的图文将会有热门“ <i class="meiye-icon meiye-remen color_y"></i>”标识；此标签展示在标题之后）</span>
              </a-checkbox>
            </p>
          </a-checkbox-group>

        </div>
      </a-row>
    </div>
    <!-- 一键置顶 -->
    <div
      class="top pointer"
      @click="scrollToBt()"
    >
      <a-icon
        type="double-right"
        :rotate="isTop?270:90"
      /> {{ isTop?'一键置顶':'文章设置' }}
    </div>
    <!-- 底部按鈕  -->
    <div class="last-btns-step2">
      <a-space :size="20">
        <a-button
          type="primary"
          @click="publish(1)"
          class="stepbtnnext1 bigheightbn wid-102"
        >保存并发布</a-button>
        <a-button
          class="stepbackbtn bigheightbn wid-46"
          @click="publish(0)"
        >保存</a-button>
        <a-button
          class="stepbackbtn bigheightbn wid-46"
          @click="watch()"
        >预览</a-button>
        <a-button
          class="stepbackbtn bigheightbn wid-46"
          @click="cancel()"
        >取消</a-button>
      </a-space>
    </div>
    <!-- 选择图片 -->
    <a-modal
      title="选择图片"
      :visible="imageObj.visible"
      @cancel="imageObj.visible=false;"
      @ok="addlinkokFn"
      :width="600"
      class="mrj_modal_normal"
    >
      <!-- 标题 传入 保存之后的id 还有type_id 4/8 -->
      <div
        class="flex"
        v-if="imageObj.list && imageObj.list.length"
      >
        <span
          class="broadImgSpan"
          v-for="(item,index) in imageObj.list"
          :key="index"
        >
          <img
            class="broadImg"
            :src="item"
            alt=""
          >
          <span
            @click="chooseCover(item)"
            :class="query.cover==item?'disnone':'broad'"
          >
            <a-icon type="check" />
          </span>
        </span>

      </div>
      <div v-else>暂未上传图片</div>
    </a-modal>
    <!-- 选择频道 -->
    <a-modal
      title="选择频道"
      :visible="openchannel.visible"
      @cancel=";openchannel.visible=false;"
      :width="600"
      @ok="publishFn"
      class="line-modal"
    >
      <div class="m-height400">
        <a-tree
          class="draggable-tree  pgl-24"
          show-icon
          :icon="customExpandIcon"
          :checkedKeys="channel_id"
          :tree-data="gData"
          @select="onchangeTree"
          :replace-fields="{children:'child', key:'id',  title: 'name'}"
        >
          <i
            slot="switcherIcon"
            class='meiye-icon meiye-jiantou meiye-jiantou_tree1'
            style="font-size: 12px;"
          ></i>
          <template
            slot="title"
            slot-scope="{ title }"
          >
            <span class="title1">
              <span>{{ title ||'--' }}</span>
              <span> </span>
            </span>

          </template>
        </a-tree>
      </div>
    </a-modal>
    <!-- 预览 -->
    <a-modal
      title="预览"
      :visible="watchObj.visible"
      @cancel=";watchObj.visible=false;"
      :width="463"
      @ok="watchObj.visible=false;"
      class=""
      :footer="null"
    >
      <div class="imgbgcontent">
        <div class="centerBOX">
          <div class="imgbgTitle">
            {{ watchObj.title }}
          </div>
          <div
            class="imgHtml"
            v-html="watchObj.html"
          >
          </div>
        </div>

      </div>
    </a-modal>
  </div>
</template>
<script>
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
// import Editor from './components/WangEditor'
import Editor from './components/Editor'
import {
  GetChannel,
  EditImgTxt,//编辑图文
  DeleteImgTxt,
  AddImgTxt,
  DetailImgTxt,
} from '@/api/content'
export default {
  inject: ['reload'],
  components: { Editor },
  data() {
    return {
      imgflag: false,
      selectedKeys: [],
      html: '',
      baseURL: process.env.VUE_APP_API_BASE_URL_MALL,
      baseUrl: process.env.VUE_APP_API_BASE_URL_MALL,
      isHot: [1],//置顶 热门 
      top: false,
      query: {
        title: undefined,
        cover: undefined,
        tag: [1, 2],
        fileUrl: undefined
      },
      imageObj: {
        visible: false,
        list: [],
        image: '',
      },
      fileList: [],
      openchannel: {
        visible: false,
        selectedKeys: [],
      },
      gData: [],
      // baseUrl: 'http://192.168.3.67:8081',
      baseUrl: process.env.VUE_APP_API_BASE_URL_MALL,
      channel_id: undefined,
      watchObj: {
        visible: false,
        html: '',
        title: '',
      },
      isTop: false,
      scrollTop: 0,
      channelflag: true,
    }
  },
  created() {
    this.getChannel()
    if (this.$route.query.type == 3 && this.$route.query.id) {
      this.detailImgTxt()
    }
  },
  methods: {
    init() {
      let obj = {
        imgflag: false,
        selectedKeys: [],
        html: '',
        baseURL: process.env.VUE_APP_API_BASE_URL_MALL,
        baseUrl: process.env.VUE_APP_API_BASE_URL_MALL,
        isHot: [1],//置顶 热门 
        top: false,
        query: {
          title: undefined,
          cover: undefined,
          tag: [1, 2],
          fileUrl: undefined
        },
        imageObj: {
          visible: false,
          list: [],
          image: '',
        },
        fileList: [],
        openchannel: {
          visible: false,
          selectedKeys: [],
        },
        gData: [],
        // baseUrl: 'http://192.168.3.67:8081',
        baseUrl: process.env.VUE_APP_API_BASE_URL_MALL,
        channel_id: undefined,
        watchObj: {
          visible: false,
          html: '',
          title: '',
        },
        isTop: false,
        scrollTop: 0,
        channelflag: true,
      }
      console.log(this.$data, 'this.$data');
      Object.assign(this.$data, this.$options.data());
      this.$refs.edit.html = ''
      this.$refs.edit.linkObj = {//链接弹框
        visible: false,
        isImgorTxt: 1,//1 文字 2图片 3小程序
        product_image: '',//图片链接地址
        txt: '',// 图片 链接地址
        txtvalue: '',//文字的链接
        dec: '',//文字链接的描述
        appid: "",//
        linkvalue: '',//小程序的链接        
        libkdec: '',//小程序描述
        watch_image: '',
      }
      this.getChannel()
      // if (this.$route.query.type == 3 && this.$route.query.id) {
      //   this.detailImgTxt()
      // }
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
      this.$forceUpdate();
    },
    getbrandsItem(i, v) {
      if (i == 'img') {
        this.selectedKeys = [v.text]
      }
    },
    detailImgTxt() {
      let data = {
        id: this.$route.query.id
      }
      DetailImgTxt(data).then(res => {
        let that = this
        if (res.error_code == 0) {
          that.query.title = res.data.data.title
          that.query.cover = res.data.data.cover
          that.$refs.edit.html = res.data.data.content
          that.query.tag = []
          res.data.data.tag.forEach(el => {
            that.query.tag.push(Number(el.tag))
          })
          that.$refs.edit.product_image = res.data.data.product_image
          console.log(that.query.tag, '/that.query.tag ')

          if (res.data.data.type == 2) {
            that.$refs.edit.txt = res.data.data.link_url
          } else if (res.data.data.type == 3) {
            that.$refs.edit.txtvalue = res.data.data.link_url
          } else if (res.data.data.type == 4) {
            that.$refs.edit.linkvalue = res.data.data.link_url
          }
          if (res.data.data.type == 2) {
            that.$refs.edit.dec = res.data.data.desc
          } else if (res.data.data.type == 4) {
            that.$refs.edit.linkvalue = res.data.data.libkdec
          }
          that.$refs.edit.appid = res.data.data.appid_mini
          that.$refs.edit.html = res.data.data.content
          that.$refs.edit.isImgorTxt = res.data.data.type == 2 ? 1 : res.data.data.type == 3 ? 2 : res.data.data.type == 4 ? 3 : undefined
          that.query.leave_word = res.data.data.leave_word
          this.channel_id = res.data.data.channel_id
          this.query.is_publish = res.data.data.is_publish
          // this.form = res.data.data

        }
      })
    },
    // 拖拽上传
    handleChange(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 10
        if (isLt1M) {
          self.$message.error('上传文件大于10MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };

      let uploadApiUrl = this.baseUrl + 'backend/v1/tools/upload_file_to_oos'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.query.cover = res.data.file_url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 上传文件 
    async filesuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };

      let uploadApiUrl = this.baseUrl + 'backend/v1/tools/upload_file_to_oos'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.query.fileUrl = res.data.file_url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 素材内容中选取
    chooseFromEdit() {
      this.imageObj.visible = true
      console.log(this.$refs.edit.editor.children, '////this.$refs.edit.objHTML');
      let list = this.$refs.edit.editor.children
      this.imageObj.list = []
      this.getUrlList(list)
      console.log(this.imageObj.list);
      // 递归选出所有 type: "image"  src

    },
    getUrlList(data) {

      data.forEach(el => {
        if (el.type == 'image') {
          this.imageObj.list.push(el.src)
        }
        if (el.children && el.children.length) this.getUrlList(el.children);
      })
    },

    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        // "Content-Type": "multipart/form-data",
        "Authorization": storage.get('token')
      };
      //配置头
      const request = axios.create({
        headers: headers
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    //一键置顶的效果
    handleScroll() {
      let scrollTop = document.getElementById("content").scrollTop;
      console.log(scrollTop, 'scrollTop');
      this.isTop = scrollTop > 20 ? true : false

    },
    scrollToBt() {
      if (this.isTop) {
        document.getElementById("content").scrollTop = 0;
      } else {
        document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight
      }

    },
    addlinkokFn() {
      this.query.cover
      console.log(this.query.cover);
      this.imageObj.visible = false
    },
    // 发布1 草稿箱0
    publish(num) {
      let that = this
      let obj = Object.assign({}, this.$refs.edit.linkObj)
      // 校验必填项  
      if (!that.query.title) {
        that.$message.error('请填写标题！')
        return false
      }
      if (!that.$refs.edit.html || that.$refs.edit.html == "<p><br></p>") {
        that.$message.error('请填写正文！')
        return false
      }
      if (num) {//is_publish=1
        this.openchannel.visible = true
      } else {
        let data = {
          title: that.query.title,
          cover: that.query.cover,
          tag: that.query.tag,
          product_image: obj.isImgorTxt == 2 ? obj.product_image : undefined,
          link_url: obj.isImgorTxt == 2 ? obj.txt : obj.isImgorTxt == 1 ? obj.txtvalue : obj.linkvalue,
          desc: obj.isImgorTxt == 2 ? undefined : obj.isImgorTxt == 1 ? obj.dec : obj.libkdec,
          appid_mini: obj.isImgorTxt == 3 ? obj.appid : undefined,
          content: that.$refs.edit.html,
          type: obj.isImgorTxt == 1 ? 2 : obj.isImgorTxt == 2 ? 3 : obj.isImgorTxt == 3 ? 4 : 1,
          leave_word: 2,
          is_publish: 0,
          id: that.$route.query.type == 1 ? undefined : that.$route.query.id
        }
        if (data.type == 2) {
          if (!obj.dec || !obj.txtvalue) {
            data.type = 1
          }
        }
        if (that.$route.query.type == 1) {
          AddImgTxt(data).then(res => {
            if (res.error_code == 0) {
              // that.$message.success('保存成功！可前往草稿箱中查看。')
              // 返回列表 去发布
              that.openchannel.visible = false
              that.$confirm({
                icon: 'exclamation-circle',
                closable: true,
                title: '提示',
                content: (
                  <div> 保存成功！可前往草稿箱中查看。</div>
                ),
                okText: '返回列表',
                closable: true,
                okType: 'primary',
                cancelText: '去发布',
                onOk() { //去发布
                  that.$router.replace({
                    path: '/content/sourcematerial'
                  })
                },
                onCancel() {//返回列表 
                  that.openchannel.visible = true
                },
              });

            } else {
              this.$message.error(res.error_msg || '保存失败')
            }
          })

        } else {
          EditImgTxt(data).then(res => {
            if (res.error_code == 0) {
              that.openchannel.visible = false
              that.$confirm({
                icon: 'exclamation-circle',
                closable: true,
                title: '提示',
                content: (
                  <div> 保存成功！可前往草稿箱中查看。</div>
                ),
                okText: '返回列表',
                closable: true,
                okType: 'primary',
                cancelText: '去发布',
                onOk() {
                  that.$router.replace({
                    path: '/content/sourcematerial'
                  })
                },
                onCancel() {
                  that.openchannel.visible = true
                },
              });
            } else {
              let self = that
              self.$confirm({
                icon: 'exclamation-circle',
                closable: true,
                title: '编辑失败',
                content: (
                  <div>
                    失败原因
                    <span class=' '>[{res.data.message}]</span>
                  </div>
                ),
                okText: '去修改',
                closable: true,
                okType: 'primary',
                cancelText: '返回',
                onOk() {
                  that.$route.replace({
                    path: '/content/sourcematerial',
                    // query: { type: 3, id}
                  })
                },
                onCancel() {
                },
              });
            }
          })
        }
      }
    },
    publishFn() {
      let that = this
      if (!that.channel_id) {
        return that.$message.error('请选择频道')
      }
      that.treeRecursion2(that.gData)
      if (!that.channelflag) {
        return that.$message.error('此频道不可选择')
      }

      let obj = Object.assign({}, this.$refs.edit.linkObj)
      let data = {
        title: that.query.title,
        cover: that.query.cover,
        tag: that.query.tag,
        product_image: obj.isImgorTxt == 2 ? obj.product_image : undefined,
        link_url: obj.isImgorTxt == 2 ? obj.txt : obj.isImgorTxt == 1 ? obj.txtvalue : obj.linkvalue,
        desc: obj.isImgorTxt == 2 ? undefined : obj.isImgorTxt == 1 ? obj.dec : obj.libkdec,
        appid_mini: obj.isImgorTxt == 3 ? obj.appid : undefined,
        content: that.$refs.edit.html,
        type: obj.isImgorTxt == 1 ? 2 : obj.isImgorTxt == 2 ? 3 : obj.isImgorTxt == 3 ? 4 : 1,
        leave_word: 2,
        channel_id: that.channel_id,
        is_publish: 1,
        id: that.$route.query.type == 1 ? undefined : that.$route.query.id
      }
      if (data.type == 2) {
        if (!obj.dec || !obj.txtvalue) {
          data.type = 1
        }
      }
      if (this.$route.query.id && this.$route.query.type == 3) {
        EditImgTxt(data).then(res => {
          if (res.error_code == 0) {
            that.openchannel.visible = false
            that.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '提示',
              content: (
                <div> 保存且发布成功！</div>
              ),
              okText: '创建新内容',
              closable: true,
              okType: 'primary',
              cancelText: '返回列表',
              onOk() { //创建新内 
                that.init()
              },
              onCancel() {//返回列表
                that.$router.push({
                  path: '/content/imagetext'
                })
                let obj = {
                  component: "CMS_Imagetext",
                  fullPath: "/content/imagetext",
                  icon: "meiye-routeicon-calendar",
                  id: 86,
                  params: "",
                  path: "imagetext",
                  pid: 84,
                  title: "图文管理",
                }
                storage.set('seconderyMenuObj', obj)
                Msg.$emit('getpathname', obj)
                that.$forceUpdate();
              },
            });
          } else {
            let self = that
            self.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '编辑失败',
              content: (
                <div>
                  失败原因
                  <span class=' '>[{res.data.message}]</span>
                </div>
              ),
              okText: '去修改',
              closable: true,
              okType: 'primary',
              cancelText: '返回',
              onOk() {
                that.$route.replace({
                  path: '/content/sourcematerial',
                  // query: { type: 3, id}
                })
              },
              onCancel() {
              },
            });

          }
        }).finally(e => {
          // that.channel_id = undefined
        })
      } else if (this.$route.query.type == 1) {
        AddImgTxt(data).then(res => {
          if (res.error_code == 0) {
            let that = this
            that.openchannel.visible = false
            that.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '提示',
              content: (
                <div> 保存且发布成功！</div>
              ),
              okText: '创建新内容',
              closable: true,
              okType: 'primary',
              cancelText: '返回列表',
              onOk() { //创建新内容 
                that.init()
              },
              onCancel() {//返回列表
                that.$router.push({
                  path: '/content/imagetext'
                })
                let obj = {
                  component: "CMS_Imagetext",
                  fullPath: "/content/imagetext",
                  icon: "meiye-routeicon-calendar",
                  id: 86,
                  params: "",
                  path: "imagetext",
                  pid: 84,
                  title: "图文管理",
                }
                storage.set('seconderyMenuObj', obj)
                Msg.$emit('getpathname', obj)
                that.$forceUpdate();
              },
            });

          } else {
            let self = that
            self.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '发布失败',
              content: (
                <div>
                  失败原因
                  <span class=' '>[{res.data.message}]</span>
                </div>
              ),
              okText: '去修改',
              closable: true,
              okType: 'primary',
              cancelText: '返回',
              onOk() {
                that.$route.replace({
                  path: '/content/sourcematerial',
                  // query: { type: 3, id}
                })
              },
              onCancel() {
              },
            });

          }
        }).finally(e => {
          // that.channel_id = undefined
        })
      }
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      if (props.child && props.child.length > 0) {
      } else {
        if (!props.id) {
          return <span style="margin-right:19px"></span>;
        }
        //这里是为了让无图标子级的父元素也给了个margin-right，让它跟有子级的父元素在同一竖线上
        return <span style="margin-right:19px"> <span class='pointer_tree '></span></span>;
      }
    },
    //递归 转化树形结构 
    treeRecursion(data) {
      data.forEach(el => {
        if (typeof (el.id) == 'number') {
          el.id = el.id.toString()
        }
        el['key'] = el.id
        el['title'] = el.name
        el['isEdit'] = false
        if (el.child && el.child.length) this.treeRecursion(el.child);
      })
    },
    // 是否能选择
    treeRecursion2(data) {
      data.forEach(el => {
        if (el.id == this.channel_id) {
          this.channelflag = el.publishable
        }
        if (el.child && el.child.length) this.treeRecursion2(el.child);
      })

    },
    getChannel() {
      GetChannel().then(res => {
        if (res.error_code == 0) {
          // this.$message.success(res.data.message)
          this.gData = res.data.list
          this.treeRecursion(this.gData)
        }
      }).finally(r => {
        // this.getList()
      })
    },
    cancel() {
      this.$router.replace({
        path: '/content/sourcematerial'
      })
    },
    onchangeTree(e) {
      this.channel_id = e[0]
    },
    watch() {
      this.watchObj.visible = true
      this.watchObj.html = this.$refs.edit.html
      this.watchObj.title = this.query.title
    },
    chooseCover(item) {
      this.query.cover = item
    },

  },
  mounted() {
    document.getElementById("content").addEventListener("scroll", this.handleScroll);
    // window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  computed: {

  },

}
</script>
<style lang="less" scoped>
.addBox {
  margin: 0 0 0 0px;
  // background: #fff;
  background-color: #f0f2f5;
  // padding: 0px 48px 0 0px;
  height: calc(100vh - 89px);
  display: flex;
  flex-direction: column;
}
.content {
  padding: 30px 48px 0 0px;
  margin: 20px 18px 18px;
  background: #fff;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 120px;
  border-radius: 4px 4px 0 0;
  .chooses {
    display: inline-block;
    width: 228px;
    height: 36px;
    line-height: 36px;
    background: #f0f5f4;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(45, 56, 53, 0.8);
    text-align: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .imgdrop {
      width: 76px;
      text-align: center;
      height: 14px;
      line-height: 14px;
      display: inline-block;
      border-right: 1px solid #d0e3e1;
      .meiye-xiala {
        font-size: 12px;
        color: @fontColor1;
      }
    }
    .addvideo {
      width: 76px;
      height: 14px;
      height: 14px;
      line-height: 14px;
      display: inline-block;
      border-right: 1px solid #d0e3e1;
    }
    .addLink {
      width: 76px;
      display: inline-block;
    }
  }
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.last-btns-step2 {
  height: 68px;
  margin-left: 18px;
  width: calc(100vw - 217px);
  position: fixed;
  bottom: 18px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 2;
  padding-left: 178px;
}
.twoh2 {
  display: flex;
  margin-bottom: 10px;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 2px;
    margin: 7px 8px 0 0;
  }
  .ttxt {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: @fontColor1;
    line-height: 25px;
  }
}
.noborder_input {
  border: none;
  box-shadow: none;
  background: #fff;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor1;
  width: 750px;
  &::placeholder {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    // font-weight: 600;
    color: #a4b3b1;
  }
}
.upBox {
  width: 100%;
  text-align: left;
  .upcenter {
    height: 154px;
    width: auto;
    margin: 0 auto;
    img {
      width: auto;
      height: 154px;
    }
  }
  .upCom {
    display: inline-block;
    // width: 360px;
    width: 90%;
    height: 154px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.01);
    border-radius: 6px;
    border: 1px dashed #ccdada;
    &:hover {
      border: 1px dashed @primaryColor;
      background: rgba(11, 199, 185, 0.05);
    }
  }
  /deep/.ant-upload.ant-upload-drag {
    width: 100%;
    height: 154px;
    background: rgba(170, 170, 170, 0);
    border-radius: 6px;
    // border: 1px solid #ccdada;
    border: none;
  }
}
.leftLable {
  display: inline-block;
  width: 134px;
  margin-left: 10px;
}
/deep/.ant-checkbox-group {
  margin-top: 0px;
}
.fontC4 {
  color: #a4b3b1;
}
.wid-48 {
  width: 48px;
}
.wid-102 {
  width: 102px;
}
.meiye-leixing {
  color: rgb(158, 166, 164);
  font-size: 15px;
}
.pdl-35 {
  padding-left: 33px;
}
.mgt-15 {
  margin-top: 15px;
}
.mgb-20 {
  margin-bottom: 20px;
}
.mgb-10 {
  // margin-bottom: 10px;
}
.mgt-52 {
  margin-top: 52px;
}
.color_y {
  color: red;
}
/deep/.ant-upload-list {
  display: none;
}
.meiye-shangchuan {
  font-size: 32px;
  color: @primaryColor;
}
.broadImgSpan {
  display: inline;
  width: 60px;
  height: 60px;
  position: relative;
  margin-right: 12px;
  .broadImg {
    width: 100%;
    height: 100%;
  }
  .broad {
    display: none;
    position: absolute;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    i {
      color: #fff;
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      z-index: 1000;
      font-weight: 600;
      font-size: 24px;
    }
  }
  &:hover {
    .broad {
      display: inline-block;
    }
  }
  .disnone {
    display: inline-block !important;
    position: absolute;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    i {
      color: #fff;
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      z-index: 1000;
      font-weight: 600;
      font-size: 24px;
    }
  }
}
.mgt--12 {
  margin-top: -12px !important;
}
/deep/.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}
.imgbgcontent {
  width: 399px;
  height: 691px;
  background: url(../../../assets/images/phonebg.png) no-repeat 100% 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .centerBOX {
    width: 360px;
    margin: 27px 6px 16px 21px;
    overflow: auto;
  }
  .imgbgTitle {
    height: 60px;
    width: 330px;
    margin: 18px auto 12px;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: @fontColor;
    overflow: hidden;
  }
  .imgHtml {
    flex: 1;
    width: 330px;
    // height: 545px;
    margin: 0px auto 34px;
    // overflow: hidden;
    // overflow: auto;
    // overflow: hidden;
    // overflow-y: auto;
  }
}
/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  display: none;
}
.pointer_tree {
  display: block;
  margin-top: 14px;
  width: 6px;
  height: 6px;
  background: @fontColor5;
  border-radius: 50%;
  margin-left: 2px;
  margin-top: 10px;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  // background: ;
}
/deep/.ant-tree
  li
  span.ant-tree-switcher.ant-tree-switcher_close
  .ant-tree-switcher-icon,
/deep/.ant-tree
  li
  span.ant-tree-switcher.ant-tree-switcher_close
  .ant-select-switcher-icon {
  transform: scale(0.83333333) rotate(270deg);
}

.top {
  position: fixed;
  bottom: 120px;
  right: 48px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  border: 1px solid #ccd8d6;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor5;
}
/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  display: none;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: @baseColor57;
  color: @baseColor11;
  /deep/ &:hover {
    background: @baseColor57 !important;
    color: @baseColor11;
  }
}
/deep/.ant-tree-treenode-selected .meiye-jiantou_tree1 {
  color: @fontColor4 !important;
}
/deep/.ant-tree-treenode-switcher-close .meiye-jiantou_tree1 {
  color: @fontColor5 !important;
}
.pdt-12 {
  padding-top: 12px;
}
</style>