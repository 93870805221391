<template>
  <div class="pageBox">
    <a-spin
      class="posi_spin"
      v-if="loading"
    />
    <div
      class="page_input"
      v-if="pageStatus=='list'"
    >
      <a-input
        class="ant-input_1 wid-330 mgt-27 mgl-32"
        placeholder="请输入标题/关键字"
        v-model="keyword"
        @change="getList()"
      ><i
          class="meiye-icon meiye-sousuo1 font-14 font5"
          slot="prefix"
        ></i></a-input>
    </div>

    <div
      v-if="pageStatus=='list'"
      class="sourcematerial tab-container"
      id="tabContainer"
    >
      <div
        class="items itemAdd tab-item pointer"
        @click="addRules('',1)"
        :class="isShowAdd?'':'itemAdd2'"
      >
        <i class="meiye-icon meiye-routeicon-add"></i>
        <span class=" block ">创建图文消息</span>
      </div>
      <div
        class="items tab-item"
        :class="isShowAll?'item3':''"
        v-for="(item,idx) in table.tableData"
        :key="idx"
      >
        <div
          class="item_err"
          v-if="item.fail_reason"
        >
          <a-row class="mgt-12 mgl-12 t-left"> <i class="meiye-icon meiye-xingzhuang"></i><span class="err_tips">{{item.fail_reason}}</span></a-row>
          <a-row class="mgl-12 mgb-12 t-left"> <!--<span class="err_tips_time">本草稿于 09:23 发布失败</span>--> <span class="baseColor11 pointer font-14">查看详情</span></a-row>
        </div>
        <div class="item_top">
          <span
            class="item_img1"
            v-bind:style="{ background:  'url('+item.cover +')',backgroundRepeat: 'no-repeat', backgroundSize: 'cover','backgroundPosition':'50% 50%',}"
          ></span>
          <span
            class="item_del item_del3"
            @click="actions(item,'delete')"
          >
            <a-tooltip>
              <template slot="title">
                删除
              </template>
              <i class="meiye-icon meiye-shanchu"></i>
            </a-tooltip>

          </span>
          <span
            class="item_del item_del2"
            @click="actions(item,'detail')"
          >
            <a-tooltip>
              <template slot="title">
                详情
              </template>
              <i class="meiye-icon meiye-yulan"></i>
            </a-tooltip>
          </span>
          <span
            class="item_del item_del1"
            @click="actions(item,'edit')"
          >
            <a-tooltip>
              <template slot="title">
                编辑
              </template>
              <i class="meiye-icon meiye-moren"></i>
            </a-tooltip>
          </span>
          <span
            class="item_pub flex align-item-center"
            @click="actions(item,'publish')"
          ><i class="meiye-icon meiye-daochu"></i>
            <span class="posi_rela"> <span class="daochu_ab">发布</span> </span></span>
        </div>
        <div class="item_title  ">{{item.title}} </div>
        <div class="item_time t-left "> 更新时间 {{ item.updated_at }} </div>

      </div>
      <!-- 选择频道 -->
      <a-modal
        title="选择频道"
        :visible="openchannel.visible"
        @cancel=";openchannel.visible=false;"
        :width="600"
        @ok="updataChannel"
        class="line-modal"
      >
        <div class="m-height400">
          <a-tree
            class="draggable-tree  pgl-24"
            show-icon
            :icon="customExpandIcon"
            :checkedKeys="channel_id"
            :tree-data="gData"
            @select="onSelect"
            :replace-fields="{children:'child', key:'id',  title: 'name'}"
          >
            <i
              slot="switcherIcon"
              class='meiye-icon meiye-jiantou meiye-jiantou_tree1'
              style="font-size: 12px;"
            ></i>
            <template
              slot="title"
              slot-scope="{ title }"
            >
              <span class="title1">
                <span>{{ title||'--' }}</span>
                <span> </span>
              </span>

            </template>
          </a-tree>
        </div>
      </a-modal>
    </div>
    <!-- 分页器 -->
    <div
      class="paginationbox"
      v-if="pageStatus=='list'"
    >
      <a-pagination
        :default-current="1"
        :total="table.pagination.total"
        :v-model="table.pagination.page"
        @change="onChange"
      />
    </div>

    <div
      class=" flex-1"
      v-if="pageStatus=='create'"
    >
      <Add></Add>
    </div>
    <div
      class=" flex-1"
      v-if="pageStatus=='detail'"
    >
      <Detail></Detail>
    </div>

  </div>

</template>
<script>
import config from '../config';
import Msg from '@/components/mrj-menu/msg'
import {
  GetChannel,
  GetImgTxt,
  DeleteImgTxt,
  AddImgTxt,
  EditImgTxt
} from '@/api/content'
import Add from './add'
import Detail from './detail'

export default {
  components: { Add, Detail },
  data() {
    return {
      config,
      pageStatus: 'list',// create  detail 
      screenWidth: null,//屏幕宽度 
      columns: 5,
      openchannel: {
        visible: false,
        selectedKeys: [],
      },
      gData: [],
      list: [],
      loading: false,
      isShowAdd: false,
      query: {
        page: 1,
        limit: 2,
        total: 0
      },
      table: {
        tableData: [],
        pagination: {
          limit: 2,
          page: 1,
          total: 0
        }
      },
      isShowAll: false,
      channel_id: undefined,//频道选择id
      keyword: undefined,
    }
  },
  methods: {
    /**
     * @param { string } wrapIdName    容器id(或class)名称
     * @param { string } contentIdName 容器中内容项id(或class)名称
     * @param { number } column        容器中内容展示列数 手机的话建议改为2
     * @param { number } columnGap     容器中 列 间隔距离 默认为20
     * @param { number } rowGap        容器中 行 间隔距离 默认为20
     */
    //瀑布流方法：通过拿到dom循环，给每一个dom添加对应的定位位置排列出瀑布流布局。
    //通过判断列的高度，来把下一个盒子放在最短的地方补上
    waterFall(
      wrapIdName,
      contentIdName,
      columns = 5,
      columnGap = 24,
      rowGap = 24
    ) {
      // 获得内容可用宽度（去除滚动条宽度）
      const wrapContentWidth = document.querySelector(wrapIdName).offsetWidth - 69;
      // 间隔空白区域
      const whiteArea = (columns - 1) * columnGap;
      // 得到每列宽度(也即每项内容宽度)
      const contentWidth = parseInt((wrapContentWidth - whiteArea) / columns);
      // 得到内容项集合
      const contentList = document.querySelectorAll(contentIdName);
      // 成行内容项高度集合
      const lineConentHeightList = [];
      for (let i = 0; i < contentList.length; i++) {
        // 动态设置内容项宽度
        contentList[i].style.width = contentWidth + "px";

        // 获取内容项高度
        const height = contentList[i].clientHeight;

        if (i < columns) {
          // 第一行按序布局
          contentList[i].style.top = 0;
          contentList[i].style.left = contentWidth * i + columnGap * i + "px";

          // 将行高push到数组
          lineConentHeightList.push(height);
        } else {
          // 其他行
          // 获取数组最小的高度 和 对应索引
          let minHeight = Math.min(...lineConentHeightList);
          let index = lineConentHeightList.findIndex(
            (listH) => listH === minHeight
          );

          contentList[i].style.top = minHeight + rowGap + "px";
          contentList[i].style.left = (contentWidth + columnGap) * index + "px";

          // 修改最小列的高度 最小列的高度 = 当前自己的高度 + 拼接过来的高度 + 行间距
          lineConentHeightList[index] += height + rowGap;
        }
      }
    },
    styleObj(url) {
      console.log(url);
      return {
        background: `url(${url}) no-repeat;`
      }
    },
    // 确认回调
    updataChannel() {
      let that = this
      if (!that.channel_id) {
        return that.$message.error('请选择频道')
      }
      that.treeRecursion2(that.gData)
      if (!that.channelflag) {
        return that.$message.error('此频道不可选择')
      }
      let tag = []
      this.openchannel.tag.forEach(el => {
        tag.push(Number(el.tag))
      })
      // ok 选择的频道 
      let data = {
        title: this.openchannel.title,
        type: this.openchannel.type,
        link_url: this.openchannel.link_url,
        desc: this.openchannel.desc,
        appid_mini: this.openchannel.appid_mini,
        content: this.openchannel.content,
        cover: this.openchannel.cover,
        leave_word: this.openchannel.leave_word,
        is_publish: 1,
        tag: this.openchannel.tag,
        channel_id: this.channel_id,
        id: this.openchannel.id
      }
      if (!this.channel_id) {
        return this.$message.error('请选择频道')
      }
      EditImgTxt(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success('发布成功')
          this.getList()
          this.openchannel.visible = false
        } else {
          let self = this
          self.$confirm({
            icon: 'exclamation-circle',
            closable: true,
            title: '发布失败',
            content: (
              <div>
                失败原因
                <span class=' '>[{res.data.message}]</span>
              </div>
            ),
            okText: '去修改',
            closable: true,
            okType: 'primary',
            cancelText: '返回',
            onOk() {
              this.$router.push({
                path: '/content/sourcematerial',
                query: {
                  id: this.$route.query.id,
                  type: 3
                }
              })
            },
            onCancel() {
            },
          });

        }
      }).finally(e => {
        // that.channel_id = undefined
      })
    },
    onDragEnter(info) {
      //拖拽排序调取接口   DragType
      console.log(info);
      // expandedKeys 需要受控时设置
      // this.expandedKeys = info.expandedKeys
    },
    onDrop(info) {
      console.log(info);
      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr);
          }
          if (item.child) {
            return loop(item.child, key, callback);
          }
        });
      };
      const data = [...this.gData];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, item => {
          item.child = item.child || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.child.push(dragObj);
        });
      } else if (
        (info.node.child || []).length > 0 && // Has child
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.child = item.child || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.child.unshift(dragObj);
        });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      this.gData = data;
    },
    //递归 转化树形结构 
    treeRecursion(data) {
      data.forEach(el => {
        if (typeof (el.id) == 'number') {
          el.id = el.id.toString()
        }
        el['key'] = el.id
        el['title'] = el.name
        el['isEdit'] = false
        if (el.child && el.child.length) this.treeRecursion(el.child);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      if (props.child && props.child.length > 0) {
      } else {
        if (!props.id) {
          return <span style="margin-right:19px"></span>;
        }
        //这里是为了让无图标子级的父元素也给了个margin-right，让它跟有子级的父元素在同一竖线上
        return <span style="margin-right:19px"> <span class='pointer_tree '></span></span>;
      }
    },
    onSelect(selectedKeys, info) {
      console.log('selected', selectedKeys, info);
      this.selectedKeys = selectedKeys
      this.channel_id = selectedKeys[0]
    },
    // 是否能选择
    treeRecursion2(data) {
      data.forEach(el => {
        if (el.id == this.channel_id) {
          this.channelflag = el.publishable
        }
        if (el.child && el.child.length) this.treeRecursion2(el.child);
      })

    },
    addRules(i, e) {
      if (i) {//编辑
        this.$router.replace({ path: '/content/sourcematerial', query: { type: e, id: i.id } })
      } else {//添加
        this.$router.replace({ path: '/content/sourcematerial', query: { type: e } })
      }

    },
    // 
    getChannel() {
      GetChannel().then(res => {
        if (res.error_code == 0) {
          // this.$message.success(res.data.message)
          this.gData = res.data.list
          this.treeRecursion(this.gData)
        }
      }).finally(r => {
        // this.getList()
      })
    },
    getList() {
      let self = this
      self.loading = true
      self.isShowAll = false
      // self.isShowAdd = false
      const params = {
        page: self.table.pagination.page,
        limit: 10,
        is_publish: 0,
        channel_id: 0,
        keyword: self.keyword
      }
      return GetImgTxt(params).then(res => {
        console.log(res.data, "data");
        self.loading = true
        self.isShowAll = true
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination.total = total
        let timer = setTimeout(() => {
          self.waterFall("#tabContainer", ".tab-item", this.columns); //实现布局
          clearTimeout(timer)
          timer = null
          self.isShowAll = false
          self.isShowAdd = true
          self.$forceUpdate()
        })//600
        console.log(self.table.tableData, "///self.table.tableData");
        // self.waterFall("#tabContainer", ".tab-item", this.columns); //实现布局

      }).finally(r => {
        self.loading = false
      })
    },
    onChange(page, limit) {
      console.log(page, limit, '///分頁變化');
      this.table.pagination.page = page
      this.getList()
    },
    actions(record, i) {
      if (i == 'delete') {
        // 二次确认
        let that = this
        that.$confirm({
          icon: 'exclamation-circle',
          closable: true,
          title: '提示',
          content: (
            <div> 删除之后将不在列表展示，是否确认删除？。</div>
          ),
          okText: '确认',
          closable: true,
          okType: 'primary',
          cancelText: '取消',
          onOk() { //去发布 
            let data = {
              id: [record.id]
            }
            DeleteImgTxt(data).then(res => {
              if (res.error_code == 0) {
                that.$message.success(res.data.message)
                that.getList()
              }
            })
          },
        });
        return false
      }
      if (i == 'detail') {//去详情页
        this.$router.replace({
          path: '/content/sourcematerial',
          query: {
            type: 2,
            id: record.id,
          }
        })
        return false
      }
      if (i == 'edit') {
        // this.tag=
        this.$router.replace({
          path: '/content/sourcematerial',
          query: {
            type: 3,
            id: record.id,
          }
        })
        return false
      }
      if (i == 'publish') {
        let tag = []
        record.tag.forEach(el => {
          tag.push(Number(el.tag))
        })
        let data = {
          title: record.title,
          type: record.type,
          link_url: record.link_url,
          desc: record.desc,
          appid_mini: record.appid_mini,
          content: record.content,
          cover: record.cover,
          leave_word: record.leave_word,
          is_publish: 1,
          tag: tag,
          id: record.id,
          channel_id: this.channel_id,
          fail_reason: record.fail_reason,
          update_at: record.update_at
        }

        this.getChannel()
        this.openchannel = Object.assign(this.openchannel, data)
        this.openchannel.visible = true
      }

    },
  },
  created() {
    if (this.$route.query.type && this.$route.query.type == 1) {// 添加
      this.pageStatus = 'create'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)

    } else if (this.$route.query.type && this.$route.query.type == 3) { //编辑
      this.pageStatus = 'create'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else if (this.$route.query.type && this.$route.query.type == 2) { //查看
      this.pageStatus = 'detail'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else { //列表
      this.pageStatus = 'list'
      this.getList()
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', true)
        clearTimeout(timers)
        timers = ''
      },)
    }

  },

  mounted() {
    //获取屏幕尺寸 
    this.screenWidth = document.body.clientWidth
    if (this.screenWidth <= 1280) {
      console.log('1280')
      this.columns = 2
    } else if (this.screenWidth > 1280 && this.screenWidth <= 1440) {
      console.log('1280 ~ 1440 ')
      this.columns = 3
    } else if (this.screenWidth > 1440 && this.screenWidth <= 1680) {
      console.log('1440 ~ 1680 ')
      this.columns = 4
    } else if (this.screenWidth > 1680) {
      console.log(' >1680 ')
      this.columns = 5
    }
    // let timer = setTimeout(() => {
    //   this.waterFall("#tabContainer", ".tab-item", this.columns); //实现布局
    //   clearTimeout(timer)
    //   timer = null
    // }, 600)//600

    window.onresize = () => {
      console.log('高度');
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth
        this.waterFall("#tabContainer", ".tab-item", this.columns); //实现瀑布流
      })()
    }
  },
  watch: {
    screenWidth: function (n, o) {
      if (n <= 1280) {
        console.log('1280')
        this.columns = 2
      } else if (n > 1280 && n <= 1440) {
        console.log('1280 ~ 1440 ')
        this.columns = 3
      } else if (n > 1440 && n <= 1680) {
        console.log('1440 ~ 1680 ')
        this.columns = 4
      } else if (n > 1680) {
        console.log(' >1680 ')
        this.columns = 5
      }
    }
  },
  computed: {
  },
}
</script>
<style lang="less" scoped>
.pageBox {
  background: #f0f5f4;
  height: calc(100vh - 52px);
  overflow: hidden;
  overflow-y: auto;
  .page_input {
    width: 100%;
    height: 59px;
  }
}
.sourcematerial {
  background: #f0f5f4;
  padding: 0px 36px 0 33px;
  height: calc(100vh - 244px);
  margin-left: 33px;
  margin-top: 26px;
  margin-bottom: 36px;
  .itemAdd {
    height: 165px !important;
    min-width: 280px;
    text-align: center;
    line-height: 165px !important;
    background: #ffffff;
    box-shadow: inset 0px -3px 0px 0px #dbeae8 !important;
    border-radius: 8px;
    border: 1px solid #ccd8d6 !important;
    font-size: 16px;
    font-weight: 500;
    color: @fontColor4;
    display: flex;
    flex-direction: column;
    // margin-top: -21px;
    cursor: pointer;
    &:hover {
      border: 1px solid @primaryColor !important;
      box-shadow: inset 0px -3px 0px 0px @primaryColor !important;
      color: @baseColor11;
      .meiye-routeicon-add {
        color: @baseColor11;
      }
    }
    .meiye-routeicon-add {
      font-size: 36px;
      height: 34px;
      color: @fontColor4;
      font-weight: 500;
      margin-top: -12px;
      margin-bottom: 12px;
    }
    .block {
      height: 14px;
    }
  }
  .itemAdd2 {
    height: 165px !important;
    min-width: 280px;
    text-align: center;
    line-height: 165px !important;
    background: #f0f5f4 !important;
    box-shadow: none !important;
    border-radius: 8px;
    border: none !important;
    font-size: 16px;
    font-weight: 500;
    z-index: 10;
    .meiye-routeicon-add,
    .block {
      display: none;
    }
    &:hover {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .items {
    opacity: 1;
    background: #ffffff;
    box-shadow: inset 0px -3px 0px 0px #dbeae8;
    border-radius: 8px;
    border: 1px solid #ccd8d6;
    margin-right: 24px;
    margin-bottom: 24px;
    height: 100%;
    overflow: hidden;
    .item_err {
      width: 100%;
    }
    .item_top {
      height: 100%;
      width: 100%;
      position: relative;
      word-wrap: break-word;
      hyphens: auto;
      // padding-bottom: 42.55319149%;
      background: #ccd8d6;
      .item_img {
        // height: 100%;
        width: 100%;
        height: auto;
        // background: url(../../../assets/images/bloc.png);
      }
      .item_img1 {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #e3e4e5;
        width: 100%;
        height: auto;
        padding-bottom: 42.55319149%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: block;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
      }
      .item_pub {
        position: absolute;
        right: 12px;
        display: inline-block;
        width: 60px;
        height: 26px;
        line-height: 27px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        border-radius: 13px;
        border: 1px solid #ccd8d6;
        margin-left: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @fontColor4;
        top: 12px;
        display: none;
        cursor: pointer;
        &:hover {
          color: @baseColor11;
        }
        .meiye-icon {
          display: inline-block;
          margin-top: -1px;
        }
      }

      .item_del1 {
        display: none;
        right: 80px;
      }
      .item_del2 {
        display: none;
        right: 114px;
      }
      .item_del3 {
        display: none;
        right: 148px;
      }
      .item_del {
        position: absolute;
        // display: inline-block;
        display: none;
        top: 12px;
        width: 26px;
        height: 26px;
        line-height: 24px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        border-radius: 14px;
        border: 1px solid #ccd8d6;
        cursor: pointer;
        .meiye-icon {
          color: @fontColor4;
          font-size: 14px;
        }
        &:hover {
          .meiye-icon {
            color: @baseColor11;
            font-size: 14px;
          }
        }
      }
    }
    .item_title {
      width: 100%;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @fontColor4;
      padding: 12px 12px 16px 12px;
      text-align: left;
    }
    .item_time {
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a4b3b1;
      padding-bottom: 16px;
      text-align: left;
      margin-left: 12px;
    }
    // &:hover {
    //   .item_del {
    //     display: inline-block;
    //   }
    //   .item_pub {
    //     display: inline-block;
    //   }
    // }
    &:hover {
      border: 1px solid @primaryColor;
      box-shadow: inset 0px -3px 0px 0px @primaryColor;
      // .meiye-shanchu,
      // .meiye-yulan,
      // .meiye-moren,
      // .meiye-daochu,
      .item_pub,
      .item_del {
        display: inline-block;
      }
    }
  }
  .items2 {
    .item_top {
      height: 55.3%;
      width: 100%;
      position: relative;
      .item_img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .item3 {
    opacity: 0;
    box-shadow: none;
  }
}

.tab-container {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}
.paginationbox {
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: inset 0px 1px 0px 0px #d8eceb;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
  padding-right: 36px;
}
/* 每个小盒子 */
.tab-container .tab-item {
  position: absolute;
  height: auto;
  // border: 1px solid #ccc;
  box-shadow: inset 0px -3px 0px 0px #dbeae8;
  // background: white;
  /* 元素不能中断显示 */
  break-inside: avoid;
  text-align: center;
}
.meiye-xingzhuang {
  color: #ffbf00;
  font-size: 15px;
  margin-right: 6px;
}
.err_tips {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor4;
}
.err_tips_time {
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
}
.t-left {
  text-align: left;
}
.draggable-tree {
  margin-top: -10px;
}
/deep/.ant-modal-body {
  padding-top: 20px;
}

.pointer_tree {
  display: block;
  margin-top: 14px;
  width: 6px;
  height: 6px;
  background: @fontColor5;
  border-radius: 50%;
  margin-left: 2px;
  margin-top: 10px;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  // background: ;
}
/deep/.ant-tree
  li
  span.ant-tree-switcher.ant-tree-switcher_close
  .ant-tree-switcher-icon,
/deep/.ant-tree
  li
  span.ant-tree-switcher.ant-tree-switcher_close
  .ant-select-switcher-icon {
  transform: scale(0.83333333) rotate(270deg);
}
/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  display: none;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: @baseColor57;
  color: @baseColor11;
  /deep/ &:hover {
    background: @baseColor57 !important;
    color: @baseColor11;
  }
}
.wid-330 {
  width: 330px;
}
.mgt-27 {
  margin-top: 27px;
}
/deep/.ant-input_1 .ant-input {
  padding-left: 34px;
}
.posi_spin {
  position: absolute;
  left: calc(50% - 20px);
  top: 350px;
  z-index: 1;
}
/deep/.ant-input_1 .ant-input-prefix {
  left: 9px;
}
/deep/.ant-tree-treenode-selected .meiye-jiantou_tree1 {
  color: @fontColor4 !important;
}
/deep/.ant-tree-treenode-switcher-close .meiye-jiantou_tree1 {
  color: @fontColor5 !important;
}
.m-height400 {
  // margin-right: -25px;
}
.posi_rela {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 16px;
  .daochu_ab {
    position: absolute;
    display: inline-block;
    width: 24px;
    right: 0;
    top: -4px;
    font-size: 12px;
  }
}
</style>