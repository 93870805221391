<template>
  <div style="border: none;">
    <a-row class="mgl-12">
      <span class="chooses flex align-item-center">
        <a-upload
          class="uploader"
          :action="baseUrl+'backend/v1/tools/upload_file_to_oos'"
          :customRequest="uploadFiles"
          accept="image/*"
          @change="handleChange1"
        >
          <span class="imgdrop pointer">图片</span>
        </a-upload>
        <a-upload
          class="uploader"
          :action="baseUrl+'backend/v1/tools/upload_file_to_oos'"
          :customRequest="videouploadFiles"
          accept="video/*"
          @change="handleChange1"
        >
          <span class="addvideo pointer">视频</span>
        </a-upload>
        <!-- <span
          class="addvideo pointer"
          @click="insertText('video','https://sandbox.static.meirenji.vip/upload/2023/08/25/c7f957b6b6897c46117c0462d82c97d1.mp4')"
        >视频</span> -->
        <span
          class="addLink pointer"
          @click="linkObj.visible = true"
        >外链</span>
        <span
          class="addLink pointer"
          @click="insertText('card')"
        >卡片</span>
        <a-upload
          class="uploader"
          :action="baseUrl+'backend/v1/tools/upload_file_to_oos'"
          :customRequest="fileuploadFiles"
          :before-upload="beforeUploadFile"
          @change="handleChangeFile"
          accept=".doc,.docx,.xml,.pdf,.xmind,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        >
          <span class="addvideo addFile pointer">附件</span>
        </a-upload>

      </span>
    </a-row>
    <Toolbar
      style="border-bottom:none;"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <div class="re_posi">
      <Editor
        id="rela_box"
        style="border-bottom: 1px solid #D3DEDC;min-height:400px;padding-bottom:130px;"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
        @onChange="onChange"
      />

    </div>
    <!-- 添加外链 -->
    <a-modal
      title="添加外链"
      :visible="linkObj.visible"
      @cancel="linkObj.visible=false;"
      @ok="addlinkokFn"
      :width="600"
      class="mrj_modal_normal"
    >
      <div>
        <a-form
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-item
            required
            label="展示方式"
            style="margin-bottom:28px;"
          >
            <a-radio-group
              class="lable_radiogroup"
              :default-value="1"
              style="width:224px;"
              v-model="linkObj.isImgorTxt"
            >
              <a-radio-button
                :value="1"
                style="width:74px;text-align: center;"
              >
                文字
              </a-radio-button>
              <a-radio-button
                :value="2"
                style="width:74px;text-align: center;"
              >
                图片
              </a-radio-button>
              <a-radio-button
                :value="3"
                style="width:74px;text-align: center;"
              >
                小程序
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            v-if="linkObj.isImgorTxt==2"
            required
            label="选择照片"
            style="margin-bottom:4px;"
          >
            <!-- upload -->
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :customRequest="uploadFiles2"
              @change="handleChange1"
            >
              <img
                v-if="linkObj.product_image"
                :src="linkObj.product_image"
                alt="avatar"
              />
              <div v-else>
                <i
                  class="meiye-icon meiye-routeicon-add"
                  v-if="!url_loading"
                ></i>
                <a-icon
                  v-else
                  type="loading"
                />
                <!-- <a-icon :type="url_loading ? 'loading' : 'plus'" /> -->
              </div>
            </a-upload>

          </a-form-item>
          <a-form-item
            v-if="linkObj.isImgorTxt==2"
            required
            label="链接输入"
            style="margin-bottom:32px;"
          >
            <a-input
              style="min-width: 412px;"
              class="ant-input_1"
              placeholder="输入链接"
              v-model="linkObj.txt"
            ></a-input>
          </a-form-item>

          <a-form-item
            v-if="linkObj.isImgorTxt==1"
            required
            label="链接输入"
            style="margin-bottom:28px;"
          >
            <a-input
              style="min-width: 412px;"
              class="ant-input_1"
              placeholder="输入链接"
              v-model="linkObj.txtvalue"
            ></a-input>
          </a-form-item>
          <a-form-item
            v-if="linkObj.isImgorTxt==1"
            required
            label="描述"
            style="margin-bottom:28px;"
          >
            <a-textarea
              style="min-width: 412px;"
              class="ant-input_1"
              placeholder="输入描述"
              v-model="linkObj.dec"
            ></a-textarea>
          </a-form-item>

          <a-form-item
            v-if="linkObj.isImgorTxt==3"
            required
            label="链接输入"
            style="margin-bottom:28px;"
          >
            <a-input
              style="min-width: 412px;"
              class="ant-input_1"
              placeholder="输入链接"
              v-model="linkObj.linkvalue"
            ></a-input>
          </a-form-item>
          <a-form-item
            v-if="linkObj.isImgorTxt==3"
            required
            label="小程序AppID"
            style="margin-bottom:28px;"
          >
            <a-input
              style="min-width: 412px;"
              class="ant-input_1"
              placeholder="输入小程序AppID"
              v-model="linkObj.appid"
            ></a-input>
          </a-form-item>
          <a-form-item
            v-if="linkObj.isImgorTxt==3"
            required
            label="小程序描述"
            style="margin-bottom:28px;"
          >
            <a-textarea
              style="min-width: 412px;"
              class="ant-input_1"
              placeholder="输入小程序描述"
              v-model="linkObj.libkdec"
            ></a-textarea>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
    <!-- 创建预览 -->
    <a-modal
      title="预览"
      :visible="linkObj.visible2"
      @cancel="linkObj.visible2=false;"
      @ok="addlinkokFn2"
      :width="600"
      class="mrj_modal_normal"
    >
      <!-- 标题 传入 保存之后的id 还有type_id 4/8 -->
      <div>
        <img
          :src="linkObj.watch_image"
          alt=""
        >
      </div>
    </a-modal>

  </div>
</template>
<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import axios from 'axios'
// import { IEditorConfig } from '@wangeditor/editor'
import { SlateTransforms } from '@wangeditor/editor'
import storage from 'store'
let linkObj = {}
export default Vue.extend({
  components: { Editor, Toolbar },
  data() {
    let that = this
    return {
      editor: null,
      html: '',
      linkObj: {//链接弹框
        visible: false,
        isImgorTxt: 1,//1 文字 2图片 3小程序
        product_image: '',//图片链接地址
        txt: '',// 图片 链接地址
        txtvalue: '',//文字的链接
        dec: '',//文字链接的描述
        appid: '',//
        linkvalue: '',//小程序的链接        
        libkdec: '',//小程序描述
        watch_image: '',
        fileUrl: '',//文件的链接
        fileName: '',//文件名字
      },
      toolbarConfig: {
        excludeKeys: [
          'insertVideo', // 删除视频
          'uploadVideo',
          'uploadImage',
          'group-image',
          'group-video',
          'insertImage',// 删除网络图片上传 
          'insertTable',// 删除表格 
          'fullScreen',//全屏
        ]
      },
      editorConfig: {
        placeholder: '正文部分，输入内容，可输入文本、图片、视频、音频...',
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              const form = new FormData()
              linkObj.append('file', file)
              // 发送post请求
              const instance = axios.create({
                baseURL: process.env.VUE_APP_API_BASE_URL_MALL + 'backend/v1/tools/upload_file_to_oos',
                timeout: 1000,
                headers: {
                  'Authorization': storage.get('token'),//接口需要的token
                  'Content-Type': 'multipart/form-data'
                }
              })
              instance.post('', form
              ).then(res => {
                let data = res.data.data
                console.log(data, 7777)
                //insertFn用于图片显示在编辑器上
                insertFn(
                  ` ${data.file_url}`, '', data.file_url)
              })
                .catch(err => console.log(err, 888))
            }
          },
          uploadVideo: {
            async customUpload(file, insertFn) {
              const form = new FormData()
              linkObj.append('file', file)
              // 发送post请求
              const instance = axios.create({
                baseURL: process.env.VUE_APP_API_BASE_URL_MALL + 'backend/v1/tools/upload_file_to_oos',
                timeout: 1000,
                headers: {
                  'Authorization': storage.get('token'),//接口需要的token
                  'Content-Type': 'multipart/form-data'
                }
              })
              instance.post('', form
              ).then(res => {
                let data = res.data.data
                console.log(data, 9999)
                //insertFn用于图片显示在编辑器上
                insertFn(
                  ` ${data.file_url}`, '', data.file_url)
              })
                .catch(err => console.log(err, 1111))
            }
          },
        },
      },
      mode: 'default', // or 'simple' 
      url_loading: false,
      istop: false,
      baseUrl: process.env.VUE_APP_API_BASE_URL_MALL,
      headers: {
        'Authorization': storage.get('token')
      },
      // baseUrl: 'http://192.168.3.67:8081',
      objHTML: {},
      isTop: false,

    }
  },
  methods: {
    onChange(editor) {
      this.objHTML = editor.getHtml()
      this.linkObj.txt = undefined
      console.log(this.linkObj.txt, '/linkObj2222');
      console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      // this.editorConfig.MENU_CONF['uploadImage'] = {
      //   server: this.baseUrl + '/backend/v1/tools/upload_file_to_oos'
      // }

    },
    insertText(txt, url, dec) {
      const editor = this.editor // 获取 editor 实例 
      if (editor == null) { return }
      // 调用 editor 属性和 API 
      if (txt == 'img') {
        // let str = `<p>hello</p><p><img src="https://sandbox.static.meirenji.vip/upload/2023/08/25/f34794bed2aa9eec9bc424ff6748e100.jpg" alt="aa" data-href="https://sandbox.static.meirenji.vip/upload/2023/08/25/f34794bed2aa9eec9bc424ff6748e100.jpg" style=""/></p>`
        let node1 = { type: 'image', src: url, children: [{ text: '' }] }
        editor.insertNode(node1)
        return false
      }
      if (txt == 'video') {
        let node2 = { type: 'video', src: url, width: 800, children: [{ text: '' }] }
        editor.insertNode(node2)
        return false
      }
      if (txt == 'link') {
        // let str = `<p>hello</p><p><img src="https://sandbox.static.meirenji.vip/upload/2023/08/25/f34794bed2aa9eec9bc424ff6748e100.jpg" alt="aa" data-href="https://sandbox.static.meirenji.vip/upload/2023/08/25/f34794bed2aa9eec9bc424ff6748e100.jpg" style=""/></p>`
        if (this.linkObj.isImgorTxt == 1) {
          let node = { type: 'link', url: url, target: '_blank', children: [{ text: dec }] }
          let nodeList = [node]
          SlateTransforms.insertNodes(editor, nodeList)
        }
        if (this.linkObj.isImgorTxt == 2) {
          let node1 = { type: 'image', src: url, href: dec, children: [{ text: '' }] }
          editor.insertNode(node1)
        }
        // 小程序的链接不知道是什么意思 todo
        if (this.linkObj.isImgorTxt == 3) {
          let node1 = { type: 'link', url: url, target: '_blank', children: [{ text: dec }] }
          editor.insertNode(node1)
        }
        return false
      }
      if (txt == 'card') {
        const node1 = { type: 'paragraph', children: [{ text: '地址：', bold: true }] }
        const node2 = { type: 'paragraph', children: [{ text: '联系方式：', bold: true }] }
        const nodeList = [node1, node2]
        SlateTransforms.insertNodes(editor, nodeList)
        return false
      }

    },
    addlinkokFn() {
      // 校验 
      let flag = true
      if (this.linkObj.isImgorTxt == 1) {
        if (!this.linkObj.txtvalue) {
          this.$message.error('请填写链接')
          flag = false
          return false
        }
        if (!this.linkObj.dec) {
          this.$message.error('请填写描述')
          flag = false
          return false
        }
        this.insertText('link', this.linkObj.txtvalue, this.linkObj.dec)
      } else if (this.linkObj.isImgorTxt == 2) {
        if (!this.linkObj.product_image) {
          this.$message.error('请上传图片')
          flag = false
          return false
        }
        if (!this.linkObj.txt) {
          this.$message.error('请填写链接')
          flag = false
          return false
        }
        this.insertText('link', this.linkObj.product_image, this.linkObj.txt)
      } else if (this.linkObj.isImgorTxt == 3) {
        if (!this.linkObj.linkvalue) {
          this.$message.error('请填写小程序链接')
          flag = false
          return false
        }
        if (!this.linkObj.appid) {
          this.$message.error('请填写AppID')
          flag = false
          return false
        }
        if (!this.linkObj.libkdec) {
          this.$message.error('请填写描述')
          flag = false
          return false
        }
        this.insertText('link', this.linkObj.linkvalue, this.linkObj.libkdec)
      }
      if (!flag) {
        return false
      }
      this.linkObj.visible = false
    },
    addlinkokFn2() { },
    // 插入图片
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + 'backend/v1/tools/upload_file_to_oos'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.insertText('img', res.data.file_url)
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 插入视频
    async videouploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + 'backend/v1/tools/upload_file_to_oos'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.insertText('video', res.data.file_url)
      } else {
        this.$message.error(res.error.message)
      }
    },
    async uploadFiles2(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + 'backend/v1/tools/upload_file_to_oos'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.linkObj.product_image = res.data.file_url
      } else {
        this.$message.error(res.error.message)
      }
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": storage.get('token'),
      };
      //配置头 
      const request = axios.create({
        headers: headers
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    handleChange1(info) {
      if (info.file.status === 'uploading') {
        this.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        this.linkObj.visible = true
        const { response = {} } = info.file
        this.linkObj.product_image = response?.data?.file_url
        this.url_loading = false
      }
    },
    handleChangeFile(info) {
      if (info.file.status === 'uploading') {
        this.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        this.linkObj.visible = true
        const { response = {} } = info.file
        this.linkObj.fileUrl = response?.data?.file_url
        //  this.linkObj.fileName=
        this.url_loading = false
      }
    },
    async fileuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + 'backend/v1/tools/upload_file_to_oos'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.insertText('link', res.data.file_url, info.file.name)
      } else {
        this.$message.error(res.error.message)
      }
    },

    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 10
        if (isLt1M) {
          self.$message.error('上传文件大于10MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    beforeVUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 10
        // if (isLt1M) {
        //   self.$message.error('上传文件大于10MB!')
        //   reject(false)
        // }
        if (fileList.length > 1) {
          self.$message.error('只能上传一个视频文件！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    beforeUploadFile(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        // const isLt1M = file.size / 1024 / 1024 > 10
        // if (isLt1M) {
        //   self.$message.error('上传文件大于10MB!')
        //   reject(false)
        // }
        if (fileList.length > 1) {
          self.$message.error('只能上传一个文件！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['doc', 'docx', 'pdf', 'xmind', 'xlsx']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传doc, docx, pdf, xmind, xlsx类型的文件！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },


  },
  created() {
    // const editorConfig: Partial<IEditorConfig> = {  // TS 语法

  },
  mounted() {

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
})
</script>
<style  src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less"   scoped>
/deep/ .w-e-toolbar {
  border: none !important;
  background: #fff !important;
}
/deep/.w-e-text-placeholder {
  font-style: inherit;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a4b3b1;
}
.chooses {
  display: inline-block;
  width: 380px;
  height: 36px;
  line-height: 36px;
  background: #f0f5f4;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(45, 56, 53, 0.8);
  text-align: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .imgdrop {
    width: 76px;
    text-align: center;
    height: 14px;
    line-height: 14px;
    display: inline-block;
    border-right: 1px solid #d0e3e1;
    &:hover {
      color: @baseColor11;
    }
    .meiye-xiala {
      font-size: 12px;
      color: @fontColor1;
    }
  }
  .addvideo {
    width: 76px;
    height: 14px;
    line-height: 14px;
    display: inline-block;
    border-right: 1px solid #d0e3e1;
    &:hover {
      color: @baseColor11;
    }
  }
  .addLink {
    width: 76px;
    height: 14px;
    line-height: 14px;
    display: inline-block;
    border-right: 1px solid #d0e3e1;
    &:hover {
      color: @baseColor11;
    }
  }
  .addFile {
    border-right: none;
    &:hover {
      color: @baseColor11;
    }
  }
}
.uploader {
  width: 76px;
  line-height: 14px;
  height: 20px;
  display: inline-block;
  /deep/ .ant-upload.ant-upload-picture-card-wrapper {
    border: none;
  }
  /deep/ .ant-upload {
    width: 76px !important;
    line-height: 14px !important;
    height: 14px !important;
    border: none;
    background: transparent;
  }
}
/deep/ .ant-upload-list-item {
  display: none;
}
.settings {
  width: 96px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  border: 1px solid #ccd8d6;
  text-align: center;
  line-height: 32px;
  color: @fontColor3;
  position: fixed;
  bottom: 100px;
  right: 20px;
}
/deep/.ant-upload-list {
  display: none;
}
/deep/.w-e-hover-bar {
  // top: 28px !important;
}
/deep/.w-e-text-container {
  min-height: 200px;
}
/deep/ .meiye-routeicon-add {
  font-size: 26px;
}
</style>